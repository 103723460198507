// pixel to em ratio seems to be 3x so 2px in old style is 6px here
$black:    											#000000 !default;
$white:    											#ffffff !default;
$primary: #663399;
$gray-100: 											#F3F6F9 !default;
$gray-200: 											#EBEDF3 !default;
$gray-300: 											#E4E6EF !default;
$gray-400: 											#D1D3E0 !default;
$gray-500: 											#B5B5C3 !default;
$gray-600: 											#7E8299 !default;
$gray-700: 											#5E6278 !default;
$gray-800: 											#3F4254 !default;
$gray-900: 											#181C32 !default;
$dark:          									$gray-900 !default;
$dark-75:          									$gray-800 !default; 
$dark-65:          									$gray-700 !default; 
$dark-50:          									$gray-600 !default; 
$dark-25:          									$gray-400 !default; 
$text-muted:                  						$gray-500 !default; 
$danger: #F64E60;

body {
  display: flex;
  flex-direction: column;
  color: #3F4254;
}

html, body {
  height: 100%;
  margin: 0px;
  padding: 0px;
  font-size: 14px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif" !important;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
    margin: 0;
    font-family: Poppins, Helvetica, "sans-serif" !important;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #3F4254;
    text-align: left;
    background: #EEF0F8;
}

*, *::before, *::after {
    box-sizing: border-box;
}

.pr-lg-15, .px-lg-15 {
@media (min-width: 992px)
  {
    padding-right: 3.75rem !important;
  }
}

.bg-primary {  
  background: #663399; 
}

.font-family-base {
  font-family: Poppins, Helvetica, "sans-serif" !important;
}

$font-size-base: 1rem;
.font-size-base { font-size: $font-size-base }
.font-size-lg { font-size: $font-size-base * 1.08 }
.font-size-sm { font-size: $font-size-base * .925  }
.font-size-xs { font-size: $font-size-base * .8  }

.font-size-xl { font-size: $font-size-base * 2.5}
.font-size-xxl { font-size: $font-size-base * 3}
.font-size-xxxl { font-size: $font-size-base * 3.5}
.font-size-xxxxl { font-size: $font-size-base * 4}




.font-size-h1 { font-size: $font-size-base * 2 }
.font-size-h2 { font-size: $font-size-base * 1.75}
.font-size-h3 { font-size: $font-size-base * 1.5  }
.font-size-h4 { font-size: $font-size-base * 1.35   }
.font-size-h5 { font-size: $font-size-base * 1.25 }
.font-size-h6 { font-size: $font-size-base * 1.175 }

.font-weight-lighter        { font-weight: lighter }
.font-weight-light          { font-weight: 300 }
.font-weight-normal         { font-weight: 400 }
$font-weight-bold           : 500 ;
.font-weight-bold           { font-weight: $font-weight-bold }
$font-weight-bolder         : 600 ;
.font-weight-bolder         { font-weight: $font-weight-bolder }
.font-weight-boldest        { font-weight: 700 }
.line-height-base           { font-weight: 1.5 }

.text-white                 { color: #FFFFFF }
.text-center                { text-align: center }
.text-primary               { color: #663399 }
.text-right                 { text-align: right}
.text-left                  { text-align: left}
.text-center                { text-align: center}

.make-left {
  display: flex;
  flex-grow: 1;
  text-align: left;
}

.column-header {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  font-size: $font-size-base;
  font-weight: $font-weight-bolder;
}
.column-header-left {
  justify-content: flex-start;
}
.column-header-right {
  justify-content: flex-end;
}

.flex-row {
  display: flex;
  align-items: center;
}
.full-flex-row {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;  
}

.justify-between {
  justify-content: space-between;
}

.mr-10 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-20 { margin-right: 20px; }
.mr-25 { margin-right: 25px; }
.mr-30 { margin-right: 30px; }

.ml-5 { margin-left: 5px; }
.ml-10 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-20 { margin-left: 20px; }
.ml-25 { margin-left: 25px; }
.ml-30 { margin-left: 30px; }

.mt-10 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-20 { margin-top: 20px; }
.mt-21 { margin-top: 21px; }
.mt-22 { margin-top: 22px; }
.mt-23 { margin-top: 23px; }
//mt-25 defined below
.mt-30 { margin-top: 30px; }
.mt-35 { margin-top: 35px; }
.mt-40 { margin-top: 40px; }
.mt-l-30 { margin-top: 5em; }
.mt-l-40 { margin-top: 6em; }
.mt-l-50 { margin-top: 7em; }
.mt-l-60 { margin-top: 8em; }
.mt-l-70 { margin-top: 9em; }


.mb-2 {margin-bottom: 2px; }
.mb-10 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-20 { margin-bottom: 20px; }
.mb-25 { margin-bottom: 25px; }
.mb-30 { margin-bottom: 30px; }
.mb-35 { margin-bottom: 35px; }
.mb-40 { margin-bottom: 40px; }
.mb-l-30 { margin-bottom: 5em; }
.mb-l-40 { margin-bottom: 6em; }
.mb-l-50 { margin-bottom: 7em; }
.mb-l-60 { margin-bottom: 8em; }
.mb-l-70 { margin-bottom: 8em; }

.white-button-link {
  display: inline-block;
  align-items: center;
  justify-content: flex-center;
  border: 1px solid white !important;
  
  height: 40px !important;
  
  width: 100%;
  cursor: pointer;
  outline: none !important;
  display: inline-block;
  font-weight: 600;
  color: #3F4254;
  text-align: center;
  //vertical-align: middle !important;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 0.65rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}

.short-width {
  width: 50% !important;
}

.btn-disabled {
  opacity: 0.7;
}


@font-face {
      font-family: 'fontello';
      src: url('font/fontello.eot?99868634');
      src: url('font/fontello.eot?99868634#iefix') format('embedded-opentype'),
           url('font/fontello.woff?99868634') format('woff'),
           url('font/fontello.ttf?99868634') format('truetype'),
           url('font/fontello.svg?99868634#fontello') format('svg');
      font-weight: normal;
      font-style: normal;
    }
.demo-icon
  {
    font-family: "fontello";
    font-style: normal;
    font-weight: normal;
    speak: never;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* You can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
    }

.white-button-link:disabled {
  //opacity: 0.7;
}


.all-thanks-box {
  //margin-left: -0.75rem !important;
  //margin-right: -0.75rem !important;
  padding-bottom: 2.5rem !important;
  padding-top: 2.5rem !important;
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
  margin-top: 3.0rem !important;
  box-shadow: 0 1rem 2rem 1rem rgba(0, 0, 0, 0.1) !important;
  border-radius: 0.65rem !important;
}

.all-thanks-button {
  cursor: pointer;
  //background-color: #ffffff;
  //border: 2px solid white;
  outline: none !important;
  vertical-align: middle;
  transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
  box-shadow: 0 0.25rem 0.5rem 0 rgba(0, 0, 0, 0.05);
  margin-top: 1.25rem !important;
  text-decoration: none;
  display: inline-block;
  padding: 0.65rem 0.95rem;
  line-height: 1.35;
  border-radius: 0.42rem;
}


.separator-white {
  border-bottom: 1px solid #FFFFFF;
  margin-top: 6px;
  margin-bottom: 6px;
}

.separator-lightgray {
  border-bottom: 1px solid lightgray;
  margin-top: 6px;
  margin-bottom: 6px;
}

.c_fadeIn {
  animation: fadeIn 0.3s ease;
}

.c-box-shadow {
    box-shadow: -5px 5px 10px -5px rgba(0,0,0,.3), 5px 5px 10px -5px rgba(0,0,0,.1);
    z-index: 2;
}

.c-box-shadow-up {
    box-shadow: -5px -5px 10px -5px rgba(0,0,0,.1), 5px -5px 10px -5px rgba(0,0,0,.1);
    z-index: 2;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity:1;
  }
}



//--> animations

@mixin animation ($delay, $duration, $animation) {
  -webkit-animation-delay: $delay;
  -webkit-animation-duration: $duration;
  -webkit-animation-name: $animation;
  -webkit-animation-fill-mode: forwards; 
  -webkit-animation-timing-function: linear;

  -moz-animation-delay: $delay;
  -moz-animation-duration: $duration;
  -moz-animation-name: $animation;
  -moz-animation-fill-mode: forwards; 
  -moz-animation-timing-function: linear;

  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-fill-mode: forwards; 
  animation-timing-function: linear;
}



@mixin keyframes ($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }

  @-moz-keyframes #{$animation-name} {
    @content;
  }

  @keyframes #{$animation-name} {
    @content;
  }
}

@include keyframes(rotation-lr) {
  from  {
    transform: rotate(-90deg);
  }
  to {
    transform: rotate(0);
  }
}

@include keyframes(rotation-rl) {
  from  {
    transform: rotate(90deg);
  }
  to {
    transform: rotate(0);
  }
}

@include keyframes(slide-in) {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@include keyframes(slide-out) {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

@include keyframes(slide-right) {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(slide-left) {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@include keyframes(zoom-in) {
  from {
    opacity: 0;
    transform: scale(0.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

@include keyframes(rotation) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.invoice-content {
  @include animation(0, 0.3s, slide-right);
}
.checkout-form {
  @include animation(0, 0.3s, slide-left);
}
.invoice-details {
    overflow-x: hidden;
    overflow-y: scroll;
    height: 175px;
    max-height: 175px;
    @include animation(0, 0.3s, slide-left);
    border-top: 1px solid lightgray;

    //margin-left: -7px;
    //margin-right: -7px;    
}
.invoice-details::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.invoice-details {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.spinner:before {
    -webkit-animation: animation-spinner .5s linear infinite;
    animation: animation-spinner .5s linear infinite;
}

.spinner:before {
    width: 1.5rem;
    height: 1.5rem;
    margin-top: -0.75rem;
}
.spinner:before {
    content: '';
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 0;
    border-radius: 50%;
    border: 2px solid #663399;
    border-right: 2px solid transparent;
}

.fa-spin {
  animation: fa-spin 1s infinite linear;
}
@-webkit-keyframes fa-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}
@keyframes fa-spin{0%{-webkit-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);transform:rotate(359deg)}}

.tenant-logo {
    border: 0px solid lightgray;
    border-radius: 4px;
    //box-shadow: 0 0rem 2rem 1rem rgba(0, 0, 0, 0.05) !important;
}
.app-logo {
  opacity: 0.4;
  filter: alpha(opacity=40);
}
.carousel-status {
  display: none;
}
.carousel .control-dots {
  margin-bottom: 0;
}
.carousel .control-dots .dot {
  transition: opacity .25s ease-in;
  opacity: .3;
  filter: alpha(opacity=30);
  box-shadow: 0px 0px 0px rgba(0,0,0,0.9);
  box-shadow: 0;
  background: gray;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  cursor: pointer;
  display: inline-block;
  margin: 0 10px;

  outline: 0px solid transparent;
}
.carousel .control-dots .dot.selected, .carousel .control-dots .dot:hover {
  opacity: 1;
  background: darkgray;

  outline: 0px solid transparent;
}
.carousel .control-arrow, .carousel.carousel-slider .control-arrow {
  display: none;
}
.carousel .thumbs-wrapper {
  display: none;
}

.circle-radius {
  border-radius: 50%;
}



.grow-h {
  flex: 1;
}

/* Full height centered app container */
#app-container {

  /* Set block height using the viewport height unit, and force it to be full height, even if the containing content does not require it */
  min-height: 100vh;
  
  /* center this block for larger viewports */
  margin: 0 auto;

  /*Use max-width for better browser handling of small windows
  https://www.w3schools.com/css/css_max-width.asp
  */
  max-width: 31.25em;
  width: 100%;
 
  /* Use min-width to prevent your design from being scrunched up in tiny viewports (and instead be replaced with horizontal scroll bars) 
  */
  min-width: 18.75em;
   
  /* Add padding around your content - this is just an arbitrary number */
  padding: 1em;


  /* Add sufficient top padding so the fixed header doesn't cover up main content by defailt.  This value should be a calculated value (eg using calc(), sass, etc):
  padding for this container + height of app header; Here, that is 1em + 4em */ 
  padding-top: 5em;

  /* just used to so we can tell the content block apart from the body block */
  /*background-color: white;*/

  position: relative;
  max-height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  


}

#main-content {
  
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
  position: absolute;  
  top: 42%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding: 0 2.0em 0 2.0em;
  
}

#app-header {
  /* fix the header to the top of the viewport. */
  position: absolute;
  top: 0;
  left:0;
  right: 0;
  z-index:10;

  /* this should match the min-width in app-container. Recommend setting using a common variable (eg with Sass) */
  min-width: 300px;

  /* internal layout, centered vertically - flex layout is great for this */
  display: flex;
  flex-flow: row nowrap;
  align-items:center;
  padding-top: 0.7em;
  padding-bottom: 1.3em;
}

.app-header-content {
  /* set the h1 block as the main flex block - it will fill all unused space in the block */
  flex: 1;

  /* center content inside the block */
  text-align: center;
  vertical-align: middle;
}

#app-footer {
  /* fix the header to the top of the viewport. */
  position: fixed;
  bottom: 0;
  left:0;
  right: 0;
  z-index:10;

  /* this should match the min-width in app-container. Recommend setting using a common variable (eg with Sass) */
  min-width: 300px;

  /* internal layout, centered vertically - flex layout is great for this */
  display: flex;
  flex-flow: row nowrap;
  align-items:center;
  padding-bottom: 0.2em;
}

.app-footer-content {
  /* set the h1 block as the main flex block - it will fill all unused space in the block */
  flex: 1;

  /* center content inside the block */
  text-align: center;
}

.app-next-button {
  position: absolute;
  bottom: -200px;
}




////----HELLOOOO

.brand-dark .c_fadeIn {
    animation: fadeIn 0.2s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.btn.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    height: calc(1.5em + 1.3rem + 2px);
    width: calc(1.5em + 1.3rem + 2px);
}

.btn:not(.btn-text) {
    cursor: pointer;
}

.btn.disabled, .btn:disabled {
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.btn.disabled, .btn:disabled {
    opacity: 0.6;
    box-shadow: none;
}

.btn {
    outline: none !important;
    vertical-align: middle;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
}

.font-size-h6 {
    font-size: 1.175rem !important;
}

.font-weight-bold {
    font-weight: 500 !important;
}

.mt-25, .my-25 {
    margin-top: 6.25rem !important;
}

.mt-next-btn, .my-next-btn {
    margin-top: 3.25rem !important;
}


.btn {
    display: inline-block;
    font-weight: normal;
    color: #3F4254;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.65rem 1rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.42rem;
}

.btn-sm {
    padding: 0.2rem 0.5rem;
    font-size: 0.9rem;
    line-height: 1;
}

.label.label-xl {
    height: 24px;
    width: 24px;
    font-size: 1rem;
}
.label {
    padding: 0;
    margin: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    font-size: 0.8rem;
    background-color: #EBEDF3;
    color: #3F4254;
    font-weight: 400;
    height: 20px;
    width: 20px;
    font-size: 0.8rem;
}

.cursor-pointer {
    cursor: pointer;
}
.font-size-h6 {
    font-size: 1.175rem !important;
}
.border-white {
    border-color: #ffffff !important;
}
.border-2 {
    border-width: 2px !important;
}
.font-weight-bolder {
    font-weight: 600 !important;
}
.pl-3, .px-3 {
    padding-left: 0.75rem !important;
}
.pr-3, .px-3 {
    padding-right: 0.75rem !important;
}
.pb-2, .py-2 {
    padding-bottom: 0.5rem !important;
}
.pt-2, .py-2 {
    padding-top: 0.5rem !important;
}
.border-white {
    border-color: #ffffff !important;
}
.mr-1, .mx-1 {
    margin-right: 0.25rem !important;
}
.mar-b-10, .mar-y-10 {
    margin-bottom: 2.5rem !important;
}
.mar-t-10, .mat-y-10 {
    margin-top: 2.5rem !important;
}
.py-0 {
    padding-top:0;
    padding-bottom:0;
}

.align-items-center {
    align-items: center !important;
}

.justify-content-center {
    justify-content: center !important;
}

.flex-grow-1 {
    flex-grow: 1 !important;
}

.flex-row {
    flex-direction: row !important;
}
.d-flex {
    display: flex !important;
}


div {
    display: block;
}

.font-size-h1 {
    font-size: 2rem !important;
}

.text-white {
    color: #ffffff !important;
}

.text-white {
    color: #ffffff !important;
}

.text-white {
    color: #ffffff !important;
}

.font-weight-bold {
    font-weight: 500 !important;
}

.text-center {
    text-align: center !important;
}

.checkbox-list {
    display: flex;
    flex-direction: column;
}

.checkbox-list .checkbox {
    margin-bottom: 0rem;
}

.checkbox {
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
    margin: 0;
}

.font-size-h6 {
    font-size: 1.175rem !important;
}

.mb-7, .my-7 {
    margin-bottom: 1.75rem !important;
}
.mb-5, .my-5 {
    margin-bottom: 1.25rem !important;
}
.mb-3, .my-3 {
    margin-bottom: 1rem !important;
}

label {
    display: inline-block;
    margin-bottom: 0.5rem;
}
.checkbox > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}

input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}

button, input {
    overflow: visible;
}

input, button, select, optgroup, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

*, *::before, *::after {
    box-sizing: border-box;
}

input[type="checkbox" i] {
    background-color: initial;
    cursor: default;
    appearance: auto;
    box-sizing: border-box;
    margin: 3px 3px 3px 4px;
    padding: initial;
    border: initial;
}

.checkbox-list .checkbox span {
    margin-right: 0.75rem;
}

.checkbox.checkbox-gray-600 > span {
    border: 1px solid transparent;
}

.checkbox.checkbox-lg > span {
    height: 24px;
    width: 24px;
}

.checkbox > span {
    background-color: #f0f0f0;
    //border: 1px solid transparent;
}

.checkbox > span {
    height: 18px;
    width: 18px;
}

.checkbox > span {
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.42rem;
}

.checkbox.checkbox-lg > span:after {
    width: 6px;
    height: 12px;
}


.checkbox > span:after {
    width: 5px;
    height: 10px;
}

.checkbox > span:after {
    content: '';
    border-color: transparent;
    border-style: solid;
    border-width: 0 2px 2px 0/*rtl:ignore*/ !important;
    transform: rotate(45deg)/*rtl:ignore*/;
    margin-top: -2px;
}

.checkbox.checkbox-gray-600 > input:checked ~ span {
    background-color: #7E8299;
}

.checkbox > input:checked ~ span {
    background-color: #7E8299;
}

.checkbox > input:checked ~ span {
    transition: all 0.3s ease;
    background-color: none;
}
.checkbox.checkbox-gray-600 > input:checked ~ span:after {
    border-color: #ffffff;
}

.checkbox > input:checked ~ span:after {
    border-color: #ffffff;
}

.checkbox > input:checked ~ span:after {
    display: block;
}

.radio-list {
    display: flex;
    flex-direction: column;
}
.radio-list .radio {
    margin-bottom: 0rem;
}
.radio {
    display: flex;
    align-items: center;
    position: relative;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    transition: all 0.3s ease;
    margin: 0;
}
.radio > input {
    position: absolute;
    z-index: -1;
    opacity: 0;
}
input[type="radio"], input[type="checkbox"] {
    box-sizing: border-box;
    padding: 0;
}
.radio-list .radio span {
    margin-right: 0.75rem;
}
.radio.radio-gray-600 > span {
    border: 1px solid transparent;
}
.radio.radio-lg > span {
    height: 24px;
    width: 24px;
}
.radio > span {
    background-color: lightgray;
    border: 1px solid transparent;
}
.radio > span {
    height: 18px;
    width: 18px;
}
.radio > span {
    border-radius: 50%;
    background-color: none;
    display: flex;
    align-items: center;
    justify-content: center;
}
.radio.radio-gray-600 > input:checked ~ span {
    background-color: #7E8299;
}
.radio > input:checked ~ span {
    background-color: #663399;
}
.radio > input:checked ~ span {
    transition: all 0.3s ease;
    background-color: none;
}
.radio.radio-gray-600 > input:checked ~ span:after {
    background-color: #ffffff;
    border-color: #ffffff;
}
.radio > input:checked ~ span:after {
    background-color: #ffffff;
    border-color: #ffffff;
}
.radio > input:checked ~ span:after {
    display: block;
}
.radio.radio-lg > span:after {
    width: 8px;
    height: 8px;
}
.radio > span:after {
    width: 10px;
    height: 10px;
}
.radio > span:after {
    content: '';
    display: none;
    border-radius: 100% !important;
}

.animate-spin-1 {
    @include animation(0, 0.3s, rotation);
}

.animate-spin {
  -moz-animation: spin 1s infinite linear;
  -o-animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
  display: inline-block;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
